@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Nunito", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.active-link {
  background-color: #f0f0f0;
}

.login-screen {
  background: radial-gradient(#4b63e9, white);
}

.toprightbg {
  background-image: url("../public/assets/top-login.png");
  background-repeat: no-repeat;
  background-size: 25%;
  background-position: 100% 0%;
}

.bottomleftbg {
  background-image: url("../public/assets/bottom-login.png");
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: 25% 100%;
}

.topright_bg {
  background-image: url("../public/assets/top-register.png");
  background-repeat: no-repeat;
  background-size: 35%;
  background-position: 100% 0%;
}

.bottomright_bg {
  background-image: url("../public/assets/bottom-register.png");
  background-repeat: no-repeat;
  background-size: 44%;
  background-position: 95% 102%;
}

.homepageleft {
  background-image: url("../public/assets/homeleft.png");
  background-repeat: no-repeat;
  background-size: 18%;
  background-position: 0% 200%;
}

.homepageright {
  background-image: url("../public/assets/homeright.png");
  background-repeat: no-repeat;
  background-size: 10%;
  background-position: 100% 0%;
}

.circle_box {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  position: fixed;
  bottom: 0%;
  right: 0%;
  transform: translate(-50%, -50%);
}
.circle {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  position: fixed;
  bottom: 0%;
  right: 0%;
  transform: translate(-50%, -50%);
}

.circle:before,
.circle:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* border: 5px solid #cddc39; */
  /* border: 2px solid #cddc39; */
  border-radius: 50%;
}

.circle:before {
  animation: ripple 2s linear infinite;
}

.circle:after {
  animation: ripple 2s linear 1s infinite;
}

/* @keyframes ripple {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
    opacity: 1;
  }

  100% {
    transform: scale(1.6);
    opacity: 0;
  }
} */

/* for date picker  */
/*
.react-datepicker__calendar-icon {
  position: absolute;
  right: 0;
  z-index: 100;
  top: 8%;
  cursor: pointer;
}
.update_Profile .react-datepicker__calendar-icon {
  top: 13%;
  z-index: 39;
  cursor: pointer;
}

.register_User .react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 10px !important;
}
.update_Profile .react-datepicker__view-calendar-icon input {
  padding: 0.5rem !important;
  background-color: transparent !important;
}
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__month-select,
.react-datepicker__year-select,
.react-datepicker__header {
  background: #e8f0fe !important;
}

.react-datepicker__header__dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  padding: 10px 0px;
}

.react-datepicker__header__dropdown .react-datepicker__month-select,
.react-datepicker__header__dropdown .react-datepicker__year-select {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0px;
}

.react-datepicker__header__dropdown .react-datepicker__year-select {
  width: 60px;
}

.react-datepicker__day--selected {
  background-color: #005ae6 !important;
}

.react-datepicker__navigation:hover *::before {
  border-color: #333 !important;
}
*/
.calenderContainer .MuiStack-root {
  padding-top: 0;
  margin-left: 6px;
}

.calenderContainer .MuiFormControl-root {
  width: 100%;
  margin: 0 auto;
}
.calenderContainer .MuiInputBase-root {
  background: #eff5ff;
  width: 95%;
  margin: 0 auto;
}
.calenderContainer .MuiIconButton-root {
  position: relative;
  z-index: 99999 !important;
}
.calenderContainer .MuiInputBase-root::placeholder {
  color: #000000 !important;
}

.calenderContainer fieldset {
  border: none !important;
}

.calenderContainer input {
  padding: 10.5px 14px;
}
.update_Profile .MuiInputBase-input {
  padding: 10px 14px;
}
.update_Profile fieldset {
  border: 1px solid #d1d5db !important;
}
.update_Profile .MuiFormControl-root {
  width: 100% !important;
}

.button-container .emoji aside {
  position: absolute;
  bottom: 41px;
  height: 344px !important;
  width: 350px !important;
}

.mirror {
  transform: scaleX(-1);
}

body:has(.video_box) {
  overflow: hidden;
}
.becomeSeller .dropDown {
  display: none;
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
}
.reactEasyCrop_Container{
  height: 430px !important;
}
.becomeSeller:hover .dropDown {
  display: block;
  opacity: 1;
}
input, textarea, select {
  font-size: 16px !important; 
}
input::placeholder,
textarea::placeholder,
select::placeholder{
  font-size: 14px;
}

@keyframes slideBottom {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(70px);
  }
}
.slideBottom {
  animation: slideBottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slideBottom1 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(40px);
  }
}
.slideBottom1 {
  animation: slideBottom1 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.removeCloseIcon input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}
.loader1 .MuiCircularProgress-root{
  width: 30px !important;
  height: 30px !important;
}